import {Grid, makeStyles, Typography, withStyles} from '@material-ui/core'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import {Heading, MobileDotBackgroundGrid, PageContainer} from '../components'
import {PageLayout} from '../layout'
import {pxToRem} from '../lib'
import {GatsbyImage, SiteMetadata} from '../types'

export interface IndexPageProps {
  data: {
    site: {siteMetadata: SiteMetadata}
    landingImage: GatsbyImage
    mobileBackgroundImage: GatsbyImage
  }
}

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 500,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 375,
      marginBottom: theme.spacing(4),
    },
  },
  textGrid: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
}))

const StyledMainHeading = withStyles(theme => ({
  root: {
    '&:after': {
      content: '""',
      display: 'block',
      marginTop: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      width: 75,
      height: 10,
    },
  },
}))(Heading)

const StyledHighlightedText = withStyles(theme => ({
  root: {
    display: 'inline-block',
    backgroundColor: theme.palette.secondary.main,
    fontSize: pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0, 1),
  },
}))(Typography)

export const IndexPage: React.FC<IndexPageProps> = ({data}) => {
  const {site, landingImage, mobileBackgroundImage} = data

  if (!site || !site.siteMetadata) {
    throw new Error(
      `Site metadata doesn't exist. Please fix it before opening this page.`,
    )
  }

  const classes = useStyles()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageLayout
      title="HypeSync"
      siteName={site.siteMetadata.title}
      description="Software solutions for emerging businesses. We'll help you to minimize your development cost while letting you reach your target audience as fast as possible. Our palette of services consists of software development, UX / UI design and many more."
      lang="en"
    >
      <PageContainer>
        <Grid container alignItems="center">
          <Grid item md={6} xs={12} className={classes.textGrid}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <StyledMainHeading variant="h2" component="h1">
                      {site.siteMetadata.title}
                    </StyledMainHeading>
                  </Grid>

                  <Grid item>
                    <Heading variant="h3" component="h2">
                      Software solutions for emerging businesses.
                    </Heading>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <StyledHighlightedText>Coming soon.</StyledHighlightedText>
              </Grid>

              <MobileDotBackgroundGrid
                fluidImage={mobileBackgroundImage.childImageSharp}
                alt="Opaque dot pattern"
              />
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Img
              fluid={landingImage.childImageSharp.fluid}
              className={classes.image}
              alt="A man standing under the night sky and staring into the distant sea of stars."
            />
          </Grid>
        </Grid>
      </PageContainer>
    </PageLayout>
  )
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    landingImage: file(relativePath: {eq: "stellar-drawing.png"}) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileBackgroundImage: file(relativePath: {eq: "single-dot-drawing.png"}) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
